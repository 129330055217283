import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import axios from "axios";
import "./BookingForm.scss";
import {toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function BookingForm() {
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const navigate = useNavigate();
  const notifySuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    // transition: Flip,
    });
  const notifyFailure = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    // transition: Flip,
    });

  const [formData, setFormData] = useState({
    bookedBy: "",
    email: "",
    number: "",
    date: "",
    age: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const isFormValid = () => {
      return (
        formData.bookedBy &&
        formData.email &&
        formData.number &&
        formData.age &&
        formData.date
      );
  };
  const appendDataToGoogleSheet = async () => {
    console.log('in function');
    try {
      
      const data = {
        values: 
          [formData.bookedBy, formData.email, formData.number, formData.age, formData.date],
        class: true,
      };

      console.log(data);
  
      // Send a POST request to the backend route
      await axios.post(`https://${process.env.REACT_APP_BACKEND}/api/util/append_sheet`, data);
  
      console.log("Data appended to Google Sheet successfully!");
    } catch (error) {
      console.error("Error appending data to Google Sheet:", error);
    }
  };

  const sendMail = async () => {
    axios.post(`https://${process.env.REACT_APP_BACKEND}/api/util/confirm-class`, { email: formData.email, name: formData.bookedBy, date: formData.date, number: formData.number});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bookingData = {
      ...formData
    };


    try {
      // await axios.post(`http://${process.env.REACT_APP_BACKEND}/api/booking-class`, bookingData);
      appendDataToGoogleSheet();
      sendMail();
      notifySuccess('Booking Successful')

      setIsPopupOpen(false);
    } catch (error) {

      notifyFailure('Booking Failed')
    } finally {
      navigate(-1);
    }
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    navigate(-1);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      setIsPopupOpen(false);
      navigate(-1);
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        setIsPopupOpen(false);
        navigate(-1);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  if (!isPopupOpen) return null;
   // Calculate today's date in YYYY-MM-DD format
   const today = new Date().toISOString().split("T")[0];

  return (
    <div className="popup-overlay" onClick={handleOutsideClick}>
      <div className="popup-content">
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
            <h1>Book a Class</h1>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="bookedBy"
                  value={formData.bookedBy}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                Number:
                <input
                  type="number"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                Age:
                <input
                  type="number"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                Date:
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  min={today}
                />
              </label>

              {isFormValid() && (
                <button type="submit" className="submit-button">
                  Submit Booking
                </button>
              )}
            </form>
      </div>
    </div>
  );
}

export default BookingForm;
