import React, { useState, useEffect } from "react";
import elBahr from "../media/logos/elBa7r-logo.png";
import { Link } from "react-router-dom";
import "./Bahr.scss";

const ElBahrPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if popup has been shown in this session
    const popupShown = sessionStorage.getItem("popup");

    if (popupShown !== "true") {
      // Show the popup if it hasn't been shown
      const timer = setTimeout(() => {
        // console.log("Showing popup for the first time this session.");
        setShowPopup(true);
        
        // Mark popup as shown in sessionStorage
        sessionStorage.setItem("popup", "true");
      }, 5000); // Delay the popup by 5 seconds

      // Clean up timer on component unmount
      return () => clearTimeout(timer);
    }
  }, []);


  // const [showPopup, setShowPopup] = useState(false);
  // sessionStorage.setItem("popup", false);

  // // Trigger the pop-up after 5 seconds
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log("waiting for popup");
  //     var popup = sessionStorage.getItem("popup");
  //     console.log(popup);
  //     if (popup === 'false'){
  //       console.log('false popup');
  //     } else {
  //       console.log('true popup');
  //     }

  //     console.log("popup active");
  //     setShowPopup(true);
  //     sessionStorage.setItem("popup", 'true');
  //     popup = sessionStorage.getItem("popup");
  //     console.log(popup);
  //     // console.log(sessionStorage.getItem("popup"));
  //     if (popup === 'false'){
  //       console.log('false popup');
  //     } else {
  //       console.log('true popup');
  //     }

  //   }, 5000);

  //   // Clean up the timer if the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  // Function to close the pop-up
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup ? (
        <div className="popup" onClick={() => setShowPopup(false)}>
          <div className="popup-content">
            <span className="close-btn" onClick={handleClosePopup}>
              &times;
            </span>
            <div className="test-bahr">
              <div className="img-container">
                <img src={elBahr} alt="El Bahr" />
              </div>
              <div className="info">
                <h2>Dine at El Bahr and enjoy our special dishes!</h2>

                <p className="seaweed">Fish the way it should be!</p>
                <div className="links">
                  <Link
                    className="ig"
                    to="https://www.instagram.com/elbahr.elgouna?igsh=YmhqejRsMWVtdzR5"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </Link>
                  <Link
                    className="fb"
                    to="https://www.facebook.com/share/G2SL73Ssec9V7Rd4/?mibextid=LQQJ4d"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </Link>
                  <Link
                    to="https://www.google.com/maps/place/El+Bahr+Seafood+Restaurant,+Kite+Center+Rd,+Hurghada+2,+Red+Sea+Governorate+1982710,+Egypt/@27.4184692,33.6738604,18z/data=!4m6!3m5!1s0x1452677b6b2d1551:0xc2ca1973b2519220!8m2!3d27.4182777!4d33.6748008!16s%2Fg%2F11g0kf6s1w"
                    target="_blank"
                  >
                    <i className="fa-solid fa-location-dot"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="closed">
          <img
            src={elBahr}
            onClick={() => {
              setShowPopup(true);
            }}
          ></img>
        </div>
      )}
    </div>
  );
};

export default ElBahrPopup;
