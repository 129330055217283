import React, { useEffect, useState } from "react";
import NavBar from "../Nav_Footer/NavBar";
import Footer from "../Nav_Footer/Footer";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./Transaction.scss";

const TransactionStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const transactionStatus = queryParams.get("success");
  const userEmail = localStorage.getItem("userEmail");
  const name = localStorage.getItem("name") || "invalid_access_";
  const number = localStorage.getItem("number");
  const amount = localStorage.getItem("amount");
  const boat = localStorage.getItem("boat");
  const date = localStorage.getItem("date");
  const slot1 = localStorage.getItem("slot1");
  const slot2 = localStorage.getItem("slot2");
  const slot3 = localStorage.getItem("slot3");
  const code = localStorage.getItem("code");
  const destination = localStorage.getItem("destination");
  const dropOff = localStorage.getItem("dropoff");

  const appendDataToGoogleSheet = async () => {
    console.log("in function");
    try {
      const data = {
        values: [
          boat,
          date,
          name,
          userEmail,
          code,
          number,
          destination,
          slot1,
          slot2,
          slot3,
          dropOff,
        ],
        class: false,
      };
      console.log(data);

      // Send a POST request to the backend route
      await axios.post(`https://${process.env.REACT_APP_BACKEND}/api/util/append_sheet`, data);

      console.log("Data appended to Google Sheet successfully!");
    } catch (error) {
      console.error("Error appending data to Google Sheet:", error);
    }
  };

  useEffect(() => {
    if (name === "invalid_access_") {
      navigate("/");
      return;
    }
    const sent = sessionStorage.getItem(`booking_${boat}_${date}`) === "true";
    if (transactionStatus === "true" && !sent && name !== "invalid_access_") {
      appendDataToGoogleSheet();
      axios.post(`https://${process.env.REACT_APP_BACKEND}/api/util/confirm`, {
        name: name,
        email: userEmail,
        amount: amount,
        date: date,
        boat: boat,
        destination: destination,
        dropOff: dropOff,
        number: number,
      });
      sessionStorage.setItem(`booking_${boat}_${date}`, true);
    } else {
      if (name === "invalid_access_") {
        navigate("/");
        return;
      }
    }
  }, [[transactionStatus, navigate, userEmail, amount]]);

  return (
    <>
      <NavBar />
      <div className="transaction-status">
        {name === "invalid_access_" ? (
          ""
        ) : transactionStatus === "true" ? (
          <>
            <div className="transaction-success">
              <h1>Transaction Successful!</h1>
              <p>
                Thank you, {name}. Your deposit {amount * 0.25} EGP for {boat}{" "}
                was successful.
              </p>
              <p>Confirmation email has been sent to: {userEmail}</p>
              <p>Contact Number: {number}</p>
            </div>
            <Link
              to="/"
              className="home-link"
              onClick={() => {
                localStorage.clear();
              }}
            >
              Return to Homepage
            </Link>
          </>
        ) : (
          <>
            <div className="transaction-fail">
              <h1>Transaction Failed</h1>
              <p>Sorry, {name}. Your transaction could not be completed.</p>
              <p>Please check your payment details or try again later.</p>
            </div>
            <Link
              to="/"
              className="home-link"
              onClick={() => {
                localStorage.clear();
              }}
            >
              Return to Homepage
            </Link>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TransactionStatus;
