import React from "react";
import "./Footer.scss";
import companyLogo from "../media/logos/logo-color.png";  // Replace with your company logo path
import developerLogo from "../media/logos/Glyph_G.svg";  // Replace with your developer logo path

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Company Logo and Name */}
        <div className="footer-company">
          <img src={companyLogo} alt="Company Logo" className="footer-logo" />
          <span className="company-name">Gouna Sailing Club</span>
        </div>

        {/* Contact Info */}
        <div className="footer-contact">
          <h3>Contact Us:</h3>
          <p>Email: operations@gounasailingclub.com</p>
          <p>Phone: +20 122 452 4055</p>
        </div>

        {/* Developed By */}
        <div className="footer-developed">
          <h3>Developed By:</h3>
          {/* <img src={developerLogo} alt="Developer Logo" className="developer-logo" />
          <span className="developer-name">Glyph</span> */}
          <div className="developer-contact">
            <p>Email: malagroudi@gmail.com</p>
            <p>Phone: +20 106 623 4705</p>
          </div>
        </div>
      </div>

      {/* Social Media Links (Optional) */}
      <div className="footer-social">
      <a href='https://www.facebook.com/share/5rWkrPpanencuja8/?mibextid=LQQJ4d' className="social-link" target='_blank'>
          <i className="fa-brands fa-facebook-f"></i>
        </a>
        <a href='https://www.instagram.com/elgounasailingclub?igsh=MWNyYThpbzl0d3VhZQ%3D%3D&utm_source=qr' className="social-link" target='_blank'>
          <i className="fa-brands fa-instagram"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
